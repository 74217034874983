<template>
  <el-table
    class="table-normal manage"
    :data="data"
    style="width: 100%"
    :class="
      page == 'export-insurance'
        ? 'insurance'
        : page == 'credit'
        ? 'credit'
        : ''
    "
    :span-method="arraySpanMethod"
  >
    <template slot="empty">
      <p class="font-30 font-weight-500">ไม่พบข้อมูลที่คุณค้นหา</p>
    </template>
    <!-- <el-table-column
      label="ระยะเวลาอนุมัติวงเงิน"
      min-width="150px"
      v-if="page == 'credit'"
    >
      <template slot-scope="scope">
        <span
          v-if="checkDateExp(scope.row.creditEnd) == true"
          class="color-red-soft"
        >
          สิ้นสุดเวลาสินเชื่อ เมื่อ
          {{ scope.row.creditEnd | formatDateTH }}
        </span>
        <span v-else
          >{{ scope.row.creditStart | formatDateTH }} -
          {{ scope.row.creditEnd | formatDateTH }}</span
        >
      </template>
    </el-table-column> -->
    <el-table-column
      label="ขนาดธุรกิจ"
      min-width="150px"
      v-if="page == 'export-insurance' || page == 'credit'"
    >
      <template slot-scope="scope">
        <div
          v-if="page == 'export-insurance'"
          class="text-center"
          :class="
            checkDateExp(scope.row.insuranceEnd) == true
              ? 'color-35-disabled'
              : ''
          "
        >
          <div v-if="scope.row._id == 99999">
            <p class="text-center mg-0">
              <span
                class="color-main text-decoration-underline cursor-pointer"
                v-if="seeMore == false"
                @click="seeMore = !seeMore"
              >
                เพิ่มเติม <i class="fas fa-chevron-down"></i>
              </span>
              <span
                class="color-main text-decoration-underline cursor-pointer"
                v-else
                @click="seeMore = !seeMore"
              >
                แสดงน้อยลง <i class="fas fa-chevron-up"></i>
              </span>
            </p>
          </div>
          <div v-else>
            <span v-if="scope.row.insuranceSizeS">Size S </span>
            <span
              v-if="
                (scope.row.insuranceSizeS && scope.row.insuranceSizeM) ||
                (scope.row.insuranceSizeS && scope.row.insuranceSizeL)
              "
              >,
            </span>
            <span v-if="scope.row.insuranceSizeM">Size M </span>
            <span v-if="scope.row.insuranceSizeM && scope.row.insuranceSizeL"
              >,
            </span>
            <span v-if="scope.row.insuranceSizeL">Size L </span>
          </div>
        </div>

        <p
          class="text-center mg-0"
          :class="
            checkDateExp(scope.row.creditEnd) == true ? 'color-35-disabled' : ''
          "
          v-else
        >
          <span v-if="scope.row._id == 99999">
            <p class="text-center mg-0">
              <span
                class="color-main text-decoration-underline cursor-pointer"
                v-if="seeMore == false"
                @click="seeMore = !seeMore"
              >
                เพิ่มเติม <i class="fas fa-chevron-down"></i>
              </span>
              <span
                class="color-main text-decoration-underline cursor-pointer"
                v-else
                @click="seeMore = !seeMore"
              >
                แสดงน้อยลง <i class="fas fa-chevron-up"></i>
              </span>
            </p>
          </span>
          <span v-else>
            <span v-if="scope.row.creditSizeS">SIZE S </span>
            <span
              v-if="
                (scope.row.creditSizeS && scope.row.creditSizeM) ||
                (scope.row.creditSizeS && scope.row.creditSizeL)
              "
              >,
            </span>
            <span v-if="scope.row.creditSizeM">SIZE M </span>
            <span v-if="scope.row.creditSizeM && scope.row.creditSizeL"
              >,
            </span>
            <span v-if="scope.row.creditSizeL">SIZE L </span>
          </span>
        </p>
      </template>
    </el-table-column>
    <el-table-column label="เกรด" min-width="150px" v-if="page == 'credit'">
      <template slot-scope="scope">
        <div class="see-more-text" :class="seeMore == true ? 'show' : ''">
          <div v-if="scope.row.grades">
            <span
              v-if="scope.row.grades.length > 0"
              :class="
                checkDateExp(scope.row.creditEnd) == true
                  ? 'color-35-disabled'
                  : ''
              "
            >
              <span v-for="(grade, index) in scope.row.grades" :key="index"
                >{{ grade.gradeName }}
                <span v-if="index == scope.row.grades.length - 1"></span>
                <span v-else>, <br /></span>
                <!-- {{ index == scope.row.grades.length - 1 ? "" : ", " }} -->
              </span>
            </span>
            <span
              class="color-red-soft"
              :class="
                checkDateExp(scope.row.creditEnd) == true
                  ? 'color-35-disabled'
                  : ''
              "
              v-else
              >กรุณาแก้ไขเกรด!</span
            >
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      label="ประเภทผู้ประกอบการ"
      min-width="150px"
      v-if="page == 'credit'"
    >
      <template slot-scope="scope">
        <div v-if="scope.row.operatorType">
          <span
            v-if="scope.row.operatorType.length > 0"
            :class="
              checkDateExp(scope.row.creditEnd) == true
                ? 'color-35-disabled'
                : ''
            "
          >
            <span
              v-for="(operator, index) in scope.row.operatorType"
              :key="index"
            >
              <span v-if="operator == '1'">ผู้ส่งออก</span>
              <span v-else-if="operator == '2'">ผู้มีแผนที่จะส่งออก</span>
              <span v-else-if="operator == '3'"
                >ผู้ประกอบการผู้ผลิตเพื่อผู้ส่งออก</span
              >
              <!-- {{ grade.gradeName
            }} -->
              {{ index == scope.row.operatorType.length - 1 ? "" : ", " }}
            </span>
          </span>
          <span v-else>-</span>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      label="ประเภทอุตสาหกรรม"
      min-width="150px"
      v-if="page == 'credit'"
    >
      <template slot-scope="scope">
        <div
          class="see-more-text"
          :class="seeMore == true ? 'show' : ''"
          v-if="scope.row.cateIndustryType"
        >
          <span
            v-if="scope.row.cateIndustryType.length > 0"
            :class="
              checkDateExp(scope.row.creditEnd) == true
                ? 'color-35-disabled'
                : ''
            "
          >
            <span
              v-for="(industry, index) in scope.row.cateIndustryType"
              :key="index"
            >
              {{ returnIndustryType(industry) }}

              {{ index == scope.row.cateIndustryType.length - 1 ? "" : ", " }}

              <br v-if="index != scope.row.cateIndustryType.length - 1" />
            </span>
          </span>
          <span v-else>-</span>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      label="โครงการจากระบบ TERAK"
      min-width="150px"
      v-if="page == 'credit'"
    >
      <template slot-scope="scope">
        <div
          class="see-more-text"
          :class="seeMore == true ? 'show' : ''"
          v-if="scope.row.projectTERAK"
        >
          <span
            v-if="scope.row.projectTERAK.length > 0"
            :class="
              checkDateExp(scope.row.creditEnd) == true
                ? 'color-35-disabled'
                : ''
            "
          >
            <span v-for="(terak, index) in scope.row.projectTERAK" :key="index">
              {{ index + 1 + ". " }}
              {{ returnProjectTERAK(terak) }}
              <br v-if="index != scope.row.projectTERAK.length - 1" />
            </span>
          </span>
          <span v-else>-</span>
        </div>
      </template>
      <!-- <template slot-scope="scope">
        <span
          v-if="scope.row.grades.length > 0"
          :class="
            checkDateExp(scope.row.creditEnd) == true ? 'color-35-disabled' : ''
          "
        >
          <span v-for="(grade, index) in scope.row.grades" :key="index"
            >{{ grade.gradeName
            }}{{ index == scope.row.grades.length - 1 ? "" : ", " }}</span
          >
        </span>
        <span
          class="color-red-soft"
          :class="
            checkDateExp(scope.row.creditEnd) == true ? 'color-35-disabled' : ''
          "
          v-else
          >กรุณาแก้ไขเกรด!</span
        >
      </template> -->
    </el-table-column>
    <el-table-column
      label="วงเงินค้าขายต่อ Shipment"
      min-width="120px"
      v-if="page == 'export-insurance'"
    >
      <template slot-scope="scope">
        <div
          :class="
            checkDateExp(scope.row.insuranceEnd) == true
              ? 'color-35-disabled'
              : ''
          "
        >
          <span v-if="scope.row.shipment.length > 0">
            <span v-for="(shipment, index) in scope.row.shipment" :key="index">
              <span v-if="shipment == 1">น้อยกว่า 0.5 ล้านบาท</span>
              <span v-else-if="shipment == 2">0.5 - 1 ล้านบาท</span>
              <span v-else-if="shipment == 3">1 ล้านบาทขึ้นไป</span>
              <span v-if="index != scope.row.shipment.length - 1"
                >, <br
              /></span>
            </span>
          </span>
          <span v-else>-</span>
          <!-- <span v-if="scope.row.shipmentMin == 0 &&scope.row.shipmentMax == 0 ">-</span>
            <span v-else-if="scope.row.shipmentMin == 0 && scope.row.shipmentMax == 500000 "
              >ไม่เกิน 5 แสนบาท
            </span>
             <span v-else-if="scope.row.shipmentMin == 500000 && scope.row.shipmentMax == 0 "
              >เกิน 5 แสนบาท
            </span> -->
        </div>
      </template>
    </el-table-column>
    <el-table-column
      label="มูลค่าการส่งออก"
      min-width="130px"
      v-if="page == 'export-insurance'"
    >
      <template slot-scope="scope">
        <div
          :class="
            checkDateExp(scope.row.insuranceEnd) == true
              ? 'color-35-disabled'
              : ''
          "
        >
          <span
            v-if="scope.row.exportValue.length > 0"
            class="see-more-text"
            :class="seeMore == true ? 'show' : ''"
          >
            <span
              v-for="(exportValue, index) in scope.row.exportValue"
              :key="index"
            >
              <span v-if="exportValue == 1">ไม่เกิน 100 ล้านบาท</span>
              <span v-else-if="exportValue == 2">101-200 ล้านบาท</span>
              <span v-else-if="exportValue == 3">201-300 ล้านบาท</span>
              <span v-else-if="exportValue == 4">301-400 ล้านบาท</span>
              <span v-else-if="exportValue == 5">401-500 ล้านบาท</span>
              <span v-else-if="exportValue == 6">มากกว่า 500 ล้านบาท</span>
              <span v-if="index != scope.row.exportValue.length - 1"
                >, <br
              /></span>
            </span>
          </span>
          <span v-else>-</span>
          <!-- <span
            v-if="
              scope.row.exportValueMin == 0 && scope.row.exportValueMax == 0
            "
            >-
          </span>
          <span v-else-if="scope.row.exportValueMin == 0"
            >ไม่เกิน 200 ล้านบาท
          </span>
          <span v-else-if="scope.row.exportValueMin == 200000000"
            >200 - 500 ล้านบาท
          </span>
          <span v-else>-</span> -->
        </div>
      </template>
    </el-table-column>

    <el-table-column
      label="เทอมการชำระเงิน"
      min-width="120px"
      v-if="page == 'export-insurance'"
    >
      <template slot-scope="scope">
        <div
          :class="
            checkDateExp(scope.row.insuranceEnd) == true
              ? 'color-35-disabled'
              : ''
          "
        >
          <span v-if="scope.row.term.length > 0">
            <span v-for="(term, index) in scope.row.term" :key="index">
              <span v-if="term == 90">ไม่เกิน 90 วัน</span>
              <span v-else-if="term == 120">ไม่เกิน 120 วัน</span>
              <span v-else-if="term == 180">ไม่เกิน 180 วัน</span>
              <span v-if="index != scope.row.term.length - 1">, <br /></span>
            </span>
          </span>
          <span v-else>-</span>
          <!-- <span v-if="scope.row.term == 90">ไม่เกิน 90 วัน</span>
          <span v-else-if="scope.row.term == 120">ไม่เกิน 120 วัน </span>
          <span v-else-if="scope.row.term == 180">ไม่เกิน 180 วัน </span>
          <span v-else>-</span> -->
        </div>
      </template>
    </el-table-column>
    <el-table-column
      label="เงื่อนไขโครงการ"
      min-width="120px"
      v-if="page == 'project'"
    >
      <template slot-scope="scope">
        <div
          :class="
            checkDateExp(scope.row.registerEnd) == true
              ? 'color-35-disabled'
              : ''
          "
        >
          <!-- <span v-if="scope.row.term == 90">ไม่เกิน 90 วัน</span>
            <span v-else-if="scope.row.term == 120">ไม่เกิน 120 วัน </span>
            <span v-else-if="scope.row.term == 180">ไม่เกิน 180 วัน </span>
            <span v-else>-</span> -->
        </div>
      </template>
    </el-table-column>
    <!-- <tr>
      <td>sadfghjasdfghsdfg</td>
    </tr> -->
  </el-table>
</template>
<script>
import moment from "moment";
import { TERAK } from "@/service/axios";
import crypto from "crypto";
import { mapState } from "vuex";
import { log } from "util";
const algorithm = "aes-256-ctr";
const secretKey = "vwPH9sdmpnVjrTIQCc7qSXs11lWhzFrT";
const iv = crypto.randomBytes(16);
export default {
  props: ["data", "page"],
  data() {
    return {
      optionsRef: [],
      seeMore: false,
    };
  },
  computed: {
    ...mapState({
      optionIndustryType: (state) => state.industryType,
    }),
  },
  mounted() {
    this.getProjectByTerak("CFOE2021");
  },
  methods: {
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (row._id == 99999) {
        if (columnIndex === 0 && this.page == "export-insurance") {
          return [1, 4];
        } else if (columnIndex === 0 && this.page == "credit") {
          return [1, 5];
        }
      }
    },
    getProjectByTerak(text) {
      try {
        var ivstring = iv.toString("hex").slice(0, 16);
        const cipher = crypto.createCipheriv(algorithm, secretKey, ivstring);
        const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

        let obj = {
          iv: ivstring.toString("hex"),
          content: encrypted.toString("hex"),
        };
        TERAK.defaults.headers.common.Authorization = `Bearer ${
          obj.iv + ":" + obj.content
        }`;
        TERAK.get(`cfoe/refs`)
          .then((res) => {
            if (res.data.success) {
              this.optionsRef = res.data.obj;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (e) {
        console.log("Error: encrypt", e);
        return false;
      }
    },
    checkDateExp(val) {
      let now = new Date();
      let dateNow = moment(now).format("YYYY-MM-DD");
      let dateEnd = moment(val).format("YYYY-MM-DD");
      if (dateNow > dateEnd) {
        return true;
      } else if (dateNow == dateEnd) {
        return false;
      } else {
        return false;
      }
    },
    returnIndustryType(industry) {
      let getIndustry = this.optionIndustryType.find((f) => f.id == industry);
      return getIndustry.label;
    },
    returnProjectTERAK(terak) {
      if (this.optionsRef.length > 0) {
        let getRef = this.optionsRef.find((f) => f.id == terak);
        return getRef.projectName;
      }
    },
  },
};
</script>