var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-table',{staticClass:"table-normal manage",class:_vm.page == 'export-insurance'
      ? 'insurance'
      : _vm.page == 'credit'
      ? 'credit'
      : '',staticStyle:{"width":"100%"},attrs:{"data":_vm.data,"span-method":_vm.arraySpanMethod}},[_c('template',{slot:"empty"},[_c('p',{staticClass:"font-30 font-weight-500"},[_vm._v("ไม่พบข้อมูลที่คุณค้นหา")])]),(_vm.page == 'export-insurance' || _vm.page == 'credit')?_c('el-table-column',{attrs:{"label":"ขนาดธุรกิจ","min-width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.page == 'export-insurance')?_c('div',{staticClass:"text-center",class:_vm.checkDateExp(scope.row.insuranceEnd) == true
            ? 'color-35-disabled'
            : ''},[(scope.row._id == 99999)?_c('div',[_c('p',{staticClass:"text-center mg-0"},[(_vm.seeMore == false)?_c('span',{staticClass:"color-main text-decoration-underline cursor-pointer",on:{"click":function($event){_vm.seeMore = !_vm.seeMore}}},[_vm._v(" เพิ่มเติม "),_c('i',{staticClass:"fas fa-chevron-down"})]):_c('span',{staticClass:"color-main text-decoration-underline cursor-pointer",on:{"click":function($event){_vm.seeMore = !_vm.seeMore}}},[_vm._v(" แสดงน้อยลง "),_c('i',{staticClass:"fas fa-chevron-up"})])])]):_c('div',[(scope.row.insuranceSizeS)?_c('span',[_vm._v("Size S ")]):_vm._e(),(
              (scope.row.insuranceSizeS && scope.row.insuranceSizeM) ||
              (scope.row.insuranceSizeS && scope.row.insuranceSizeL)
            )?_c('span',[_vm._v(", ")]):_vm._e(),(scope.row.insuranceSizeM)?_c('span',[_vm._v("Size M ")]):_vm._e(),(scope.row.insuranceSizeM && scope.row.insuranceSizeL)?_c('span',[_vm._v(", ")]):_vm._e(),(scope.row.insuranceSizeL)?_c('span',[_vm._v("Size L ")]):_vm._e()])]):_c('p',{staticClass:"text-center mg-0",class:_vm.checkDateExp(scope.row.creditEnd) == true ? 'color-35-disabled' : ''},[(scope.row._id == 99999)?_c('span',[_c('p',{staticClass:"text-center mg-0"},[(_vm.seeMore == false)?_c('span',{staticClass:"color-main text-decoration-underline cursor-pointer",on:{"click":function($event){_vm.seeMore = !_vm.seeMore}}},[_vm._v(" เพิ่มเติม "),_c('i',{staticClass:"fas fa-chevron-down"})]):_c('span',{staticClass:"color-main text-decoration-underline cursor-pointer",on:{"click":function($event){_vm.seeMore = !_vm.seeMore}}},[_vm._v(" แสดงน้อยลง "),_c('i',{staticClass:"fas fa-chevron-up"})])])]):_c('span',[(scope.row.creditSizeS)?_c('span',[_vm._v("SIZE S ")]):_vm._e(),(
              (scope.row.creditSizeS && scope.row.creditSizeM) ||
              (scope.row.creditSizeS && scope.row.creditSizeL)
            )?_c('span',[_vm._v(", ")]):_vm._e(),(scope.row.creditSizeM)?_c('span',[_vm._v("SIZE M ")]):_vm._e(),(scope.row.creditSizeM && scope.row.creditSizeL)?_c('span',[_vm._v(", ")]):_vm._e(),(scope.row.creditSizeL)?_c('span',[_vm._v("SIZE L ")]):_vm._e()])])]}}],null,false,2682280544)}):_vm._e(),(_vm.page == 'credit')?_c('el-table-column',{attrs:{"label":"เกรด","min-width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"see-more-text",class:_vm.seeMore == true ? 'show' : ''},[(scope.row.grades)?_c('div',[(scope.row.grades.length > 0)?_c('span',{class:_vm.checkDateExp(scope.row.creditEnd) == true
                ? 'color-35-disabled'
                : ''},_vm._l((scope.row.grades),function(grade,index){return _c('span',{key:index},[_vm._v(_vm._s(grade.gradeName)+" "),(index == scope.row.grades.length - 1)?_c('span'):_c('span',[_vm._v(", "),_c('br')])])}),0):_c('span',{staticClass:"color-red-soft",class:_vm.checkDateExp(scope.row.creditEnd) == true
                ? 'color-35-disabled'
                : ''},[_vm._v("กรุณาแก้ไขเกรด!")])]):_vm._e()])]}}],null,false,4182661048)}):_vm._e(),(_vm.page == 'credit')?_c('el-table-column',{attrs:{"label":"ประเภทผู้ประกอบการ","min-width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.operatorType)?_c('div',[(scope.row.operatorType.length > 0)?_c('span',{class:_vm.checkDateExp(scope.row.creditEnd) == true
              ? 'color-35-disabled'
              : ''},_vm._l((scope.row.operatorType),function(operator,index){return _c('span',{key:index},[(operator == '1')?_c('span',[_vm._v("ผู้ส่งออก")]):(operator == '2')?_c('span',[_vm._v("ผู้มีแผนที่จะส่งออก")]):(operator == '3')?_c('span',[_vm._v("ผู้ประกอบการผู้ผลิตเพื่อผู้ส่งออก")]):_vm._e(),_vm._v(" "+_vm._s(index == scope.row.operatorType.length - 1 ? "" : ", ")+" ")])}),0):_c('span',[_vm._v("-")])]):_vm._e()]}}],null,false,1809935402)}):_vm._e(),(_vm.page == 'credit')?_c('el-table-column',{attrs:{"label":"ประเภทอุตสาหกรรม","min-width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.cateIndustryType)?_c('div',{staticClass:"see-more-text",class:_vm.seeMore == true ? 'show' : ''},[(scope.row.cateIndustryType.length > 0)?_c('span',{class:_vm.checkDateExp(scope.row.creditEnd) == true
              ? 'color-35-disabled'
              : ''},_vm._l((scope.row.cateIndustryType),function(industry,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(_vm.returnIndustryType(industry))+" "+_vm._s(index == scope.row.cateIndustryType.length - 1 ? "" : ", ")+" "),(index != scope.row.cateIndustryType.length - 1)?_c('br'):_vm._e()])}),0):_c('span',[_vm._v("-")])]):_vm._e()]}}],null,false,1684472990)}):_vm._e(),(_vm.page == 'credit')?_c('el-table-column',{attrs:{"label":"โครงการจากระบบ TERAK","min-width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.projectTERAK)?_c('div',{staticClass:"see-more-text",class:_vm.seeMore == true ? 'show' : ''},[(scope.row.projectTERAK.length > 0)?_c('span',{class:_vm.checkDateExp(scope.row.creditEnd) == true
              ? 'color-35-disabled'
              : ''},_vm._l((scope.row.projectTERAK),function(terak,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(index + 1 + ". ")+" "+_vm._s(_vm.returnProjectTERAK(terak))+" "),(index != scope.row.projectTERAK.length - 1)?_c('br'):_vm._e()])}),0):_c('span',[_vm._v("-")])]):_vm._e()]}}],null,false,1462501833)}):_vm._e(),(_vm.page == 'export-insurance')?_c('el-table-column',{attrs:{"label":"วงเงินค้าขายต่อ Shipment","min-width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{class:_vm.checkDateExp(scope.row.insuranceEnd) == true
            ? 'color-35-disabled'
            : ''},[(scope.row.shipment.length > 0)?_c('span',_vm._l((scope.row.shipment),function(shipment,index){return _c('span',{key:index},[(shipment == 1)?_c('span',[_vm._v("น้อยกว่า 0.5 ล้านบาท")]):(shipment == 2)?_c('span',[_vm._v("0.5 - 1 ล้านบาท")]):(shipment == 3)?_c('span',[_vm._v("1 ล้านบาทขึ้นไป")]):_vm._e(),(index != scope.row.shipment.length - 1)?_c('span',[_vm._v(", "),_c('br')]):_vm._e()])}),0):_c('span',[_vm._v("-")])])]}}],null,false,2790935870)}):_vm._e(),(_vm.page == 'export-insurance')?_c('el-table-column',{attrs:{"label":"มูลค่าการส่งออก","min-width":"130px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{class:_vm.checkDateExp(scope.row.insuranceEnd) == true
            ? 'color-35-disabled'
            : ''},[(scope.row.exportValue.length > 0)?_c('span',{staticClass:"see-more-text",class:_vm.seeMore == true ? 'show' : ''},_vm._l((scope.row.exportValue),function(exportValue,index){return _c('span',{key:index},[(exportValue == 1)?_c('span',[_vm._v("ไม่เกิน 100 ล้านบาท")]):(exportValue == 2)?_c('span',[_vm._v("101-200 ล้านบาท")]):(exportValue == 3)?_c('span',[_vm._v("201-300 ล้านบาท")]):(exportValue == 4)?_c('span',[_vm._v("301-400 ล้านบาท")]):(exportValue == 5)?_c('span',[_vm._v("401-500 ล้านบาท")]):(exportValue == 6)?_c('span',[_vm._v("มากกว่า 500 ล้านบาท")]):_vm._e(),(index != scope.row.exportValue.length - 1)?_c('span',[_vm._v(", "),_c('br')]):_vm._e()])}),0):_c('span',[_vm._v("-")])])]}}],null,false,4123529311)}):_vm._e(),(_vm.page == 'export-insurance')?_c('el-table-column',{attrs:{"label":"เทอมการชำระเงิน","min-width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{class:_vm.checkDateExp(scope.row.insuranceEnd) == true
            ? 'color-35-disabled'
            : ''},[(scope.row.term.length > 0)?_c('span',_vm._l((scope.row.term),function(term,index){return _c('span',{key:index},[(term == 90)?_c('span',[_vm._v("ไม่เกิน 90 วัน")]):(term == 120)?_c('span',[_vm._v("ไม่เกิน 120 วัน")]):(term == 180)?_c('span',[_vm._v("ไม่เกิน 180 วัน")]):_vm._e(),(index != scope.row.term.length - 1)?_c('span',[_vm._v(", "),_c('br')]):_vm._e()])}),0):_c('span',[_vm._v("-")])])]}}],null,false,1242076851)}):_vm._e(),(_vm.page == 'project')?_c('el-table-column',{attrs:{"label":"เงื่อนไขโครงการ","min-width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{class:_vm.checkDateExp(scope.row.registerEnd) == true
            ? 'color-35-disabled'
            : ''})]}}],null,false,1618484928)}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }